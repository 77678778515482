














































import './UserProjects.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUserProjectsState, IUserProject, IAllUsersUser, IUserApp } from '@store/modules/user-projects/Interfaces';
import { UserProjectsActions, UserProjectsMutations } from '@store/modules/user-projects/Types';
import { IViewMode } from '@/interfaces/IViewMode';

const NSUserProjects = namespace('storeUserProjects');

@Component({
    name: 'TheUserProjects',

    components: {
        BaseGroup: () => import('@components/BaseGroup'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
        ProjectsHeader: () => import('./components/ProjectsHeader.vue'),
        ProjectsBoard: () => import('./components/ProjectsBoard.vue'),
        ProjectCard: () => import('./components/ProjectCard.vue'),
        ProjectRow: () => import('./components/ProjectRow.vue'),
        AllUsers: () => import('./components/AllUsers.vue'),
    },
})
export default class ProjectsComponent extends Vue {
    @NSUserProjects.State((state: IUserProjectsState) => state.currentView) currentView!: 'active' | 'archive' | 'users';
    @NSUserProjects.Action(UserProjectsActions.A_GET_USER_PROJECTS) getUserProject!: () => Promise<void>;
    @NSUserProjects.Action(UserProjectsActions.A_GET_PROJECT_STATUSES) getProjectStatuses!: () => Promise<void>;
    @NSUserProjects.Action(UserProjectsActions.A_GET_ALL_USERS) getAllUsers!: () => Promise<void>;
    @NSUserProjects.Getter('filters') filters!: any[];
    @NSUserProjects.Getter('filtersApply') filtersApply!: boolean;
    @NSUserProjects.Getter('pausedProjects') pausedProjects!: IUserProject[];
    @NSUserProjects.Getter('completedProjects') completedProjects!: IUserProject[];
    @NSUserProjects.Getter('userProjectsGetting') userProjectsGetting!: boolean;
    @NSUserProjects.State((state: IUserProjectsState) => state.allUsers.users) allUsers!: IAllUsersUser[];
    @NSUserProjects.Getter('activeProjects') activeProjects!: IUserProject[];
    @NSUserProjects.State((state: IUserProjectsState) => state.currentViewMode) currentViewMode!: IViewMode;
    @NSUserProjects.Mutation(UserProjectsMutations.M_SET_USER_PROJECTS_GETTING) setUserProjectGetting!: (boolean) => void;
    filteredProjectsByUsers: any = new Map();

    async created() {
        this.setUserProjectGetting(true);
        this.getUserProject().then(() => {
            this.getProjectsData();
            this.setUserProjectGetting(false);
        })
    }

    @Watch('filters')
    watchFilters() {
        this.filteredProjectsByUsers = this.changeFilteredProjectsByUsers();
    }

    @Watch('allUsers')
    watchAllUsers() {
        this.filteredProjectsByUsers = this.changeFilteredProjectsByUsers();
    }

    get viewMode() {
        return this.currentViewMode;
    }

    get filteredCount() {
        if (this.filteredProjectsByUsers) {
            return this.filteredProjectsByUsers.length;
        }
        return null;
    }

    get projectsByUsers(): Map<IUserApp, IAllUsersUser> {
        const projectsByUsers = new Map();
        for (const user of this.allUsers) {
            for (const project of user.projects) {
                if (this.userProjectsIds.includes(project.id)) {
                    projectsByUsers.set(Object.assign({}, user), project);
                }
            }
        }
        return projectsByUsers;
    }

    get userProjectsIds() {
        const output: number[] = [];
        this.pausedProjects.forEach((item) => {
            output.push(item.id);
        })
        this.completedProjects.forEach((item) => {
            output.push(item.id);
        })
        this.activeProjects.forEach((item) => {
            output.push(item.id);
        })
        return output;
    }

    get projectsGroup() {
        return [
            {
                projects: [...this.pausedProjects ],
                title: 'Проекты на паузе',
                type: 'cards',
            },
            {
                projects: [...this.completedProjects ],
                title: 'Завершенные проекты',
                type: 'list',
            }
        ];
    }

    changeFilteredProjectsByUsers(): any {
        if (this.filters && this.filters.length) {
            let output: any = this.projectsByUsers;
            this.filters.forEach((filterObject) => {
                if (filterObject.type === 'select') {
                    if (filterObject.key === 'key.fullName') {
                        let newOutput = new Map();
                        for (const [key, value] of output) {
                            if (filterObject.selected.includes(key.fullName)) {
                                newOutput.set(Object.assign({}, key), value);
                            }
                        }
                        output = newOutput;
                    }
                    if (filterObject.key === 'value.title') {
                        let newOutput = new Map();
                        for (const [key, value] of output) {
                            if (filterObject.selected.includes(value.title)) {
                                newOutput.set(Object.assign({}, key), value);
                            }
                        }
                        output = newOutput;
                    }
                    if (filterObject.key === 'value.roles') {
                        let newOutput = new Map();
                        for (const [key, value] of output) {
                            let includes = false;
                            value.roles.forEach((role) => {
                                if (filterObject.selected.includes(role.title)) {
                                    includes = true;
                                }
                            })
                            if (includes) newOutput.set(Object.assign({}, key), value);
                        }
                        output = newOutput;
                    }
                }
            });
            return output;
        }
        return this.projectsByUsers;
    }

    async getProjectsData() {
        await Promise.all([
            this.getProjectStatuses(),
            this.getAllUsers(),
        ]);
    }
}
